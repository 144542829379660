/** @jsx jsx **/
import { Box, Container, jsx, Text, Heading, Grid, BaseStyles } from 'theme-ui'
import { FC } from 'react'
import { CollectionTemplateType, GatsbyImageType } from '~/@types/models'
import CollectionLayout from '~/components/layout/collection-layout'
import CollectionProducts from '~/containers/collection-products'
import MarkdownIt from '~/components/MarkdownIt'
import { space } from '~/gatsby-plugin-theme-ui'
import Img from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'
import ProductItem from '~/components/product-item'

type Props = CollectionTemplateType

const CollectionCollaboration: FC<Props> = ({ pageContext: { data } }) => {
  type QueryResult = {
    pageData: {
      frontmatter: {
        name: string
        image: GatsbyImageType
        imageMobile?: GatsbyImageType
      }
      html: string
    }
  }

  const query = useStaticQuery<QueryResult>(graphql`
    query {
      pageData: markdownRemark(frontmatter: { templateKey: { eq: "douglasFriedman" } }) {
        frontmatter {
          name
          image {
            ...heroImage
          }
        }
        html
      }
    }
  `)
  type BlockTextProps = {
    title: string
    description?: string
  }

  const BlockText: FC<BlockTextProps> = ({ title }: { title?: string; description?: string }) => {
    return (
      <Box
        sx={{
          h3: {
            ':before': {
              top: 22,
            },
          },
        }}
      >
        {title ? (
          <Heading
            as="h3"
            variant="title4"
            sx={{
              variant: 'variants.mark',
              mb: [15, null, 32],
              ml: [null, null, null, -64],
              pl: [null, null, null, 64],
            }}
          >
            {title}
          </Heading>
        ) : null}
      </Box>
    )
  }

  const {
    frontmatter: { name, image },
    html: content,
  } = query.pageData

  return (
    <CollectionLayout
      title={data.title}
      seoTitle={data.seo?.title || data.title}
      seoDescription={data.seo?.description || data.description}
      description={data.description}
      handle={data.handle}
      ankleContent={data.metafields?.ankleContent}
      heroHeader={{
        isExternal: true,
        ...(data.imageResponsive && {
          imageSet: data.imageResponsive,
        }),
      }}
      headerDescription={data.metafields?.headerDescription}
      containerSx={{
        minHeight: '0 !important',
      }}
    >
      <Container
        as="section"
        sx={{
          my: [50, null, 100],
        }}
      >
        <Grid gap={[70, null, null, 120]}>
          <Box as="section">
            <Grid
              gap={[40, null, null, 160]}
              columns={['5fr', null, null, '2fr 1fr']}
              sx={{
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  pl: [null, null, null, 64],
                }}
              >
                <BlockText />
                <BaseStyles>
                  <MarkdownIt content={content} />
                </BaseStyles>
              </Box>
              <Grid
                columns="1fr"
                sx={{
                  width: '100%',
                  // order: [-1, null, null, 1 ], this would stack the image first in the mobile breakpoint
                  order: [null, null, null, 1],
                }}
              >
                <Img fluid={image.childImageSharp.fluid} />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Container>

      <CollectionProducts
        collectionHandle={data.handle}
        initialData={data.products}
        pagination={data.pagination}
        data={data}
        gridVariant="layout.template-default"
      >
        {props => (
          <Grid variant="layout.template-default" className="product-list-collaboration">
            {props.products.map(item => {
              return (
                <Box key={item.handle} mb={space.l1}>
                  <ProductItem
                    product={item}
                    imageSize=""
                    gridLayout="template-default"
                    collectionHandle={data.handle}
                  />
                </Box>
              )
            })}
          </Grid>
        )}
      </CollectionProducts>
    </CollectionLayout>
  )
}

export default CollectionCollaboration
